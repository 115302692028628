import router from 'next/router';
import * as React from 'react';
import { ProductTeaser } from '~source/core/models/components/molecules/product-teaser';
import { PageContext } from '~source/core/models/components/templates/page';
import { sendAnalyticsOfItemList } from '~source/core/services/e-commerce/e-commerce';
import Container from '~source/ui/components/containers/container/container';
import GridItem from '~source/ui/components/containers/grid-item/grid-item';
import PaginationBar from '~source/ui/components/molecules/pagination-bar/pagination-bar';
import Grid from '~source/ui/components/templates/grid/grid';
import { useInitData } from '~source/ui/hooks/useApplicationConfig';
import { cx } from '~source/ui/utils/join-classnames';
import { addQueryString } from '~source/ui/utils/urls/url-query';
import ProductItem from './product-list.item';
import $ from './product-list.module.scss';

type Props = {
  currentPage?: number;
  hasPadding?: boolean;
  id?: string;
  layout: number;
  pathname?: string;
  products?: ProductTeaser[];
  resultsPending?: boolean;
  query?: string;
  title?: string;
  totalPages?: number;
  playerContext?: PageContext['player'];
};

const getWidePositionIndexes = (layout: number) => {
  // TODO: identify the pattern of the positions
  if (layout === 1) {
    return [3, 6, 7, 10, 11];
  }
  if (layout === 2) {
    return [3, 9, 13];
  }
  if (layout === 3) {
    return [3, 15];
  }
  return [];
};

const getUrlToReplace = () => {
  const { basePath, query } = router;
  const { slugs } = query;
  if (!Array.isArray(slugs)) return `${basePath}/${slugs}`;
  return `${basePath}/${slugs.join('/')}`;
};

const queryObj = (newPage: number, query?: string) => {
  const page = {
    page: newPage,
  };
  if (!query) return page;
  return {
    query,
    ...page,
  };
};

const ProductList = ({
  currentPage,
  hasPadding = true,
  id,
  layout,
  pathname,
  products = [],
  resultsPending = false,
  query,
  title,
  totalPages = 0,
  playerContext,
}: Props) => {
  const showPagination = totalPages > 1;
  const widePositionIndexes = getWidePositionIndexes(layout);
  const holidayLabels = useInitData()?.holidayLabels;
  const containerRef = React.useRef<HTMLElement>(null);

  const onPageChange = async (newPage: number) => {
    await router.push(
      {
        pathname: pathname || addQueryString(getUrlToReplace(), {}),
        query: queryObj(newPage, query),
      },
      undefined,
      { shallow: true, scroll: true },
    );
  };

  React.useEffect(() => {
    if (products.length > 0)
      sendAnalyticsOfItemList({
        products,
        eventType: 'view_item_list',
        listId: id,
      });
  }, [id, products]);

  if (products.length === 0) {
    return null;
  }

  return (
    <Container
      className={cx($.container, !hasPadding && $.containerWithoutPadding)}
      ref={containerRef}
    >
      {title && <h2 className={$.title}>{title}</h2>}
      <Grid className={$.items} wrapperElement={<ul />}>
        {products.map((item, i) => (
          <React.Fragment key={item.productId}>
            <GridItem
              key={item.productId}
              l={widePositionIndexes.includes(i) ? 8 : 4}
              m={6}
              wrapperElement={<li />}
              className={cx($.gridItem)}
            >
              <ProductItem
                product={item}
                holidayLabels={holidayLabels}
                playerContext={playerContext}
                listId={id}
                index={i}
              />
            </GridItem>
          </React.Fragment>
        ))}
      </Grid>
      {showPagination && currentPage && (
        <PaginationBar
          containerRef={containerRef}
          pages={totalPages}
          currentPage={currentPage}
          onPageChange={onPageChange}
          disabled={resultsPending}
        />
      )}
    </Container>
  );
};

export default ProductList;
