import React from 'react';
import { Image } from '~source/core/models/components/atoms/image';
import $ from './holiday-label.module.scss';

type Props = {
  title: string;
  color: string;
  image: Image | null;
};

const HolidayLabel: React.FC<Props> = ({ title, color, image }) => {
  if (!image) return null;
  return (
    <div
      aria-hidden="true"
      className={$.label}
      style={{
        backgroundImage: `url(${image.url})`,
        backgroundColor: color,
      }}
    >
      <p className={$.labelTitle}>{title}</p>
    </div>
  );
};

export default HolidayLabel;
