import * as React from 'react';
import { Badge } from '~source/core/models/components/atoms/badge';
import { Price } from '~source/core/models/components/atoms/price';
import { Printing } from '~source/core/models/components/organisms/product-details';

export default function useDisplayBundlePrice({
  price,
  selectedBadge,
  inputPrinting,
  inputBackNumber,
  printingOptionsInfo,
}: {
  price: Price;
  selectedBadge?: Badge | null;
  inputPrinting?: string | boolean;
  inputBackNumber?: string | boolean;
  printingOptionsInfo: Printing[] | null;
}) {
  const [basePrice, setBasePrice] = React.useState(price);
  const [displayPrice, setDisplayPrice] = React.useState(price);

  const calculatePrice = React.useCallback(() => {
    let newOriginalPrice = basePrice.original ?? 0;
    let newSalePrice = basePrice.sale ?? null;

    const addAdditionalPrice = (additionalPrice: Price) => {
      // If both the shirt and the printings are on sale
      if (additionalPrice.sale !== null && newSalePrice !== null)
        newSalePrice += additionalPrice.sale;
      // If the shirt is not on sale but the printings are
      if (additionalPrice.sale !== null && newSalePrice === null)
        newSalePrice = (basePrice.original ?? 0) + additionalPrice.sale;
      // If the shirt is on sale but the printings are not
      if (additionalPrice.sale === null && newSalePrice !== null)
        newSalePrice += additionalPrice.original ?? 0;
      newOriginalPrice += additionalPrice.original ?? 0;
    };

    if (selectedBadge) {
      const badgePrice = selectedBadge.price;
      if (badgePrice) addAdditionalPrice(badgePrice);
    }

    if (inputPrinting) {
      const printingPrice = printingOptionsInfo?.find(
        (option) => option.printing === 'letters',
      )?.price;
      if (printingPrice) addAdditionalPrice(printingPrice);
    }

    if (inputBackNumber) {
      const backNumberPrice = printingOptionsInfo?.find(
        (option) => option.printing === 'number',
      )?.price;
      if (backNumberPrice) addAdditionalPrice(backNumberPrice);
    }

    setDisplayPrice({
      original: newOriginalPrice,
      sale: newSalePrice,
    });
  }, [
    selectedBadge,
    inputPrinting,
    inputBackNumber,
    printingOptionsInfo,
    basePrice.original,
    basePrice.sale,
  ]);

  React.useEffect(() => {
    calculatePrice();
  }, [calculatePrice]);

  return {
    displayPrice,
    setBasePrice,
  };
}
