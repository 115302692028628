import * as React from 'react';
import { SvgIcon } from '~source/core/models/components/atoms/svg-icon';

export default function IconGeneralCustomize({
  theme,
  className,
  width,
  height,
}: SvgIcon) {
  const fillColor = theme === 'white' ? '#fff' : '#181314';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || 24}
      height={height || 24}
      className={className}
      viewBox="0 0 24 24"
    >
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M19.632 3.103a2.003 2.003 0 0 0-2.53 1.266l-2.53 7.588 3.795 1.265 2.53-7.59a2.003 2.003 0 0 0-1.265-2.529m2.214 2.846-2.53 7.59a1.002 1.002 0 0 1-1.265.633l-3.795-1.266a1 1 0 0 1-.633-1.264l2.53-7.59a3 3 0 0 1 5.693 1.897M18.072 17.5c0 1.93-1.57 3.5-3.5 3.5H2v-1h7.072c1.27 0 2.32-.95 2.48-2.17v-.02c.02-.1.02-.2.02-.31 0-.45-.09-.88-.25-1.28a3.49 3.49 0 0 1 3.25-2.22c1.93 0 3.5 1.57 3.5 3.5"
      />
    </svg>
  );
}
