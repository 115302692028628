import Image from 'next/image';
import * as React from 'react';
import { DYNAMIC_PREVIEW_ENABLED_SHIRT_TYPES } from '~source/constants';
import { BASE_PATH } from '~source/constants/paths';
import { ProductTeaser } from '~source/core/models/components/molecules/product-teaser';
import { PageContext } from '~source/core/models/components/templates/page';
import { HolidayLabelData } from '~source/core/models/response/holiday-label-response';
import { sendAnalyticsOfItem } from '~source/core/services/e-commerce/e-commerce';
import HolidayLabel from '~source/ui/components/atoms/holiday-label/holiday-label';
import IconGeneralCustomize from '~source/ui/components/atoms/icon/icon.general-customize';
import Link from '~source/ui/components/atoms/Link/link';
import PriceDisplay from '~source/ui/components/atoms/price-display/price-display';
import useDisplayBundlePrice from '~source/ui/hooks/formatter/useDisplayBundlePrice/useDisplayBundlePrice';
import useImageUrl from '~source/ui/hooks/formatter/useImageUrl/useImageUrl';
import useLocale from '~source/ui/hooks/helper/useLocale/useLocale';
import { useTranslate } from '~source/ui/hooks/helper/useTranslate/useTranslate';
import useProductDetails from '~source/ui/hooks/product/useProductDetails/useProductDetails';
import { cx } from '~source/ui/utils/join-classnames';
import $ from './product-list.item.module.scss';

type Props = {
  product: ProductTeaser;
  holidayLabels?: HolidayLabelData[];
  playerContext?: PageContext['player'];
  listId?: string;
  index?: number;
  onCustomizeClick?: (product: ProductTeaser) => void;
};

function getHolidayLabel(
  itemLabels: string[],
  holidayLabels: HolidayLabelData[] | undefined,
): HolidayLabelData | undefined {
  if (!holidayLabels || holidayLabels.length < 1) return undefined;

  return holidayLabels.find((holidayLabel) =>
    itemLabels.find((itemLabel) => itemLabel === holidayLabel.evaProperty),
  );
}

const ProductItem = ({
  product,
  holidayLabels,
  playerContext,
  listId,
  index,
  onCustomizeClick,
}: Props) => {
  const {
    backendId,
    displayValue,
    subtitle,
    primaryImage,
    showCustomizeIcon,
    productStatus,
    shirtType,
    price: productPrice,
  } = product;
  const locale = useLocale();
  const t = useTranslate();

  const primaryImageUrl = useImageUrl(primaryImage, {
    width: 1000,
    height: 1000,
  });

  const [isDynamicPreviewError, setIsDynamicPreviewError] =
    React.useState(false);

  const handleAnalytics = () => {
    sendAnalyticsOfItem({
      product,
      eventType: 'select_item',
      listId,
      index,
    });
  };

  const { isDynamicShirtPreview, thumbnailUrl, blurDataUrl } =
    React.useMemo(() => {
      // If this is a supported configurable shirt, use a dynamic preview image instead
      if (
        shirtType &&
        DYNAMIC_PREVIEW_ENABLED_SHIRT_TYPES.includes(shirtType) &&
        playerContext &&
        !isDynamicPreviewError
      ) {
        const { shirtNumber } = playerContext;

        const dynamicPreviewUrl = `${BASE_PATH}/api/preview/shirt?shirtNumber=${shirtNumber}&shirtType=${shirtType}`;
        return {
          isDynamicShirtPreview: true,
          thumbnailUrl: dynamicPreviewUrl,
        };
      }

      return {
        isDynamicShirtPreview: false,
        thumbnailUrl: primaryImageUrl,
        blurDataUrl: `${BASE_PATH}/_next/image?url=${encodeURIComponent(
          primaryImageUrl,
        )}&w=16&q=1`,
      };
    }, [isDynamicPreviewError, playerContext, primaryImageUrl, shirtType]);

  const { value: bundleResponse } = useProductDetails(
    isDynamicShirtPreview ? product.productId : null,
    locale,
  );
  const { displayPrice: displayBundlePrice } = useDisplayBundlePrice({
    price: productPrice,
    inputPrinting: true,
    inputBackNumber: true,
    printingOptionsInfo: bundleResponse?.printingOptionsInfo || null,
  });

  const price = isDynamicShirtPreview ? displayBundlePrice : productPrice;

  const holidayLabelBanner = getHolidayLabel(
    product.evaProperties,
    holidayLabels,
  );

  const path = playerContext
    ? `${product.path}?shirtNumber=${playerContext.shirtNumber}`
    : product.path;

  const hasLabel = (!!holidayLabelBanner && !productStatus) || productStatus;

  return (
    <article
      className={cx($.wrapper, price.sale !== null && $.isDiscounted)}
      data-dy-sku={backendId}
      aria-labelledby={backendId}
    >
      <div className={cx($.thumbnail, !hasLabel && $.reservedLabelSpace)}>
        {showCustomizeIcon && (
          <button
            type="button"
            className={cx($.thumbnailButton, $.thumbnailButtonLeft)}
            aria-label="customize"
            onClick={() => onCustomizeClick?.(product)}
            disabled={!onCustomizeClick}
          >
            <IconGeneralCustomize />
          </button>
        )}
        <Image
          className={$.thumbnailImage}
          src={thumbnailUrl}
          alt=""
          placeholder={blurDataUrl ? 'blur' : 'empty'}
          blurDataURL={blurDataUrl}
          sizes="(min-width: 90em) 392px, (min-width: 64em) 27vw, (min-width: 56em) 41vw, 50vw"
          layout="fill"
          onError={() => setIsDynamicPreviewError(true)}
        />
      </div>

      {!!holidayLabelBanner && !productStatus && (
        <HolidayLabel {...holidayLabelBanner} />
      )}

      {productStatus === 'pre-sale' && (
        <p className={cx($.banner, $.bannerBlack)}>
          {t('PRODUCT_LIST_ITEM_PRE_SALE')}
        </p>
      )}

      <Link className={$.titleLink} link={path} onClick={handleAnalytics}>
        <div className={$.info}>
          <div className={$.infoText}>
            <h2 itemProp="name" className={$.title} id={backendId}>
              {displayValue}
            </h2>
            <span className={$.subtitle}>{subtitle}</span>
          </div>

          {price.sale !== null && price.original && (
            <PriceDisplay
              discounted
              className={$.discountedPrice}
              price={price.original}
              size="small"
            />
          )}

          {price.original && (
            <PriceDisplay
              className={$.displayPrice}
              isSalePrice={price.sale !== null}
              price={price.sale !== null ? price.sale : price.original}
              size="normal"
            />
          )}
        </div>
      </Link>
    </article>
  );
};

export default ProductItem;
