import * as React from 'react';
import BaseButton from '~source/ui/components/atoms/buttons/base-button/base-button';
import { useTranslate } from '~source/ui/hooks/helper/useTranslate/useTranslate';
import { cx } from '~source/ui/utils/join-classnames';
import clamp from '~source/ui/utils/math/clamp';
import $ from './pagination-bar.module.scss';

type Props = {
  containerRef: React.RefObject<HTMLElement>;
  pages: number;
  currentPage: number;
  onPageChange: (pageNumber: number) => void;
  disabled?: boolean;
};
type PaginationNumberProps = {
  isActive: boolean;
  pageNumber: number;
  onClick: () => void;
  disabled?: boolean;
};

function PaginationNumber({
  isActive,
  pageNumber,
  onClick,
  disabled,
}: PaginationNumberProps) {
  return (
    <BaseButton
      type="button"
      className={cx($.pageButton, isActive && $.isActive)}
      onClick={onClick}
      disabled={disabled}
    >
      {pageNumber}
    </BaseButton>
  );
}

const VISIBLE_PAGE_NUMBERS = 5;

function PaginationBar({
  pages,
  currentPage,
  onPageChange,
  disabled,
  containerRef,
}: Props) {
  const t = useTranslate();
  const pageNumbers = React.useMemo(() => {
    const visiblePageNumbers = VISIBLE_PAGE_NUMBERS;
    const middleIndex = Math.floor(visiblePageNumbers / 2);
    const startingNumber = Math.min(
      Math.max(currentPage - middleIndex, 1),
      pages - middleIndex - 2,
    );

    const result: number[] = [1];
    if (pages > 1) {
      // start at 1 as the initial array already contains the first page,
      // and do one less iteration.
      for (let i = 1; i < visiblePageNumbers - 1; i += 1) {
        const nextNumber = startingNumber + i;

        // only add the number if it is between 1 and pages, otherwise skip.
        if (nextNumber > 1 && nextNumber < pages) {
          result.push(nextNumber);
        }
      }
      // End the array always with the last page number

      result.push(pages);
    }
    return result;
  }, [pages, currentPage]);

  const handlePageChange = (nextPage: number) => {
    onPageChange(clamp(1, nextPage, pages));
    if (containerRef.current)
      containerRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === pages;

  return (
    <nav className={$.container}>
      <BaseButton
        className={cx(
          $.navigationButton,
          $.navigationButtonPrevious,
          isFirstPage && $.isHidden,
        )}
        type="button"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={disabled || isFirstPage}
      >
        <span className={$.text}>{t('PAGINATION_PREVIOUS')}</span>
        <span className={$.arrow}>{'<'}</span>
      </BaseButton>
      <div className={$.pagination}>
        {pageNumbers.map((pageNumber, index, { length }) => {
          const startingDots = index === 1 && pageNumber !== 2;
          const endingDots = index === length - 2 && pageNumber !== pages - 1;
          const isActive = currentPage === pageNumber;
          return (
            <div key={pageNumber}>
              {startingDots && (
                <span className={$.pageButtonDots} aria-hidden="true">
                  ...
                </span>
              )}
              <PaginationNumber
                pageNumber={pageNumber}
                onClick={() => handlePageChange(pageNumber)}
                isActive={isActive}
                disabled={disabled}
              />
              {endingDots && (
                <span className={$.pageButtonDots} aria-hidden="true">
                  ...
                </span>
              )}
            </div>
          );
        })}
      </div>
      <BaseButton
        className={cx(
          $.navigationButton,
          $.navigationButtonNext,
          isLastPage && $.isHidden,
        )}
        type="button"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={disabled || isLastPage}
      >
        <span className={$.text}>{t('PAGINATION_NEXT')}</span>
        <span className={$.arrow}>{'>'}</span>
      </BaseButton>
    </nav>
  );
}

export default PaginationBar;
